import { COUNTRY_SHORTS } from '@/constants'
import { getRenterTotalAmount } from '@/lib/financial'
import dayjs from '~/lib/useDates'
import {
  BadgeColors,
  BookingPreferencesTypeEnumKey,
  BookingPseudoStage,
  BookingStageEnumKey,
  CancellationPolicyOptions,
  CancellationTypeEnumKey,
  DeclinationActions,
  DeclineOrCancelReasonDetailsEnumKey,
  DeclineOrCancelReasonEnumKey,
  RvUsageType,
  RentalTypeEnumKey
} from '../enums'
import { isTravelTrailer as isRVTravelTrailer } from '../rvs'
import { AllowFestivalEventsParticipation } from '@/lib/enums/frontendEnums'

export const isSpecialOffer = (booking) => booking.IsSpecialOffer
export const isCanceled = (booking) => /Canceled/.test(booking.Stage)
export const isDeclined = (booking) => /Declined/.test(booking.Stage)
export const isExpired = (booking) => /Expired/.test(booking.Stage)
export const isInactive = (booking) =>
  isCanceled(booking) || isDeclined(booking) || isExpired(booking) || isTripEnded(booking)

export const isEnquiry = (booking) => booking.Stage === BookingStageEnumKey.EnquiryRequest
export const isDepositTaken = (booking) => booking.Stage === BookingStageEnumKey.DepositTaken
export const isAwaitingOwnerApproval = (booking) => booking.Stage === BookingStageEnumKey.AwaitingOwnerApproval
export const isAwaitingPayment = (booking) => booking.Stage === BookingStageEnumKey.AwaitingPayment
export const isInstantbook = (booking) => booking.IsInstabook
export const isBookingCustomQuote = (booking) => booking?.CustomTotalRentalAmount > 0
export const isBookingEzyMatch = (booking) => booking?.IsEZYmatch
export const hasStarted = (booking) => {
  const stagesAfterStarted = [
    BookingStageEnumKey.TripStarted,
    BookingStageEnumKey.Completed,
    BookingStageEnumKey.CompleteInProcess,
    BookingStageEnumKey.Invoice,
    BookingStageEnumKey.ProcessCompleteFail,
    BookingStageEnumKey.PendingDispute,
    BookingStageEnumKey.PendingInsuranceClaim
  ]

  return stagesAfterStarted.includes(booking.Stage)
}

export const isTripEnded = (booking) => {
  const stagesAfterTripEnded = [
    BookingStageEnumKey.Completed,
    BookingStageEnumKey.CompleteInProcess,
    BookingStageEnumKey.ProcessCompleteFail,
    BookingStageEnumKey.PendingDispute,
    BookingStageEnumKey.PendingInsuranceClaim
  ]
  let isEnded = stagesAfterTripEnded.includes(booking.Stage)
  if (!isEnded) {
    isEnded = dayjs(booking.DateEnd) < dayjs()
  }
  return isEnded
}

export const isReadyToReview = (booking, isOwner) => {
  return isOwner ? booking.CanHostCreateReview : booking.CanGuestCreateReview
}

export const isWaitingOnReview = (booking, isOwner) => {
  return isOwner
    ? booking.OwnerCloseoutOn && booking.CanGuestCreateReview
    : booking.RenterCloseoutOn && booking.CanHostCreateReview
}

export const isBookingConfirmed = (booking) => {
  const stages = [
    BookingStageEnumKey.DepositTaken,
    BookingStageEnumKey.FullPaymentReceived,
    BookingStageEnumKey.SecurityDepositTaken,
    BookingStageEnumKey.PendingDispute,
    BookingStageEnumKey.PendingPayout,
    BookingStageEnumKey.PendingInsuranceClaim,
    BookingStageEnumKey.TripStarted,
    BookingStageEnumKey.InstantBooking,
    BookingStageEnumKey.CompleteInProcess,
    BookingStageEnumKey.ProcessCompleteFail,
    BookingStageEnumKey.PendingPayoutOnCancel
  ]
  return stages.includes(booking.Stage)
}

export const isPaymentStage = (booking) => {
  const stages = [BookingStageEnumKey.AwaitingOwnerApproval, BookingStageEnumKey.AwaitingPayment]
  return stages.includes(booking.Stage)
}

export const isNotHappening = (booking) => {
  return isCanceled(booking) || isDeclined(booking) || isExpired(booking)
}

export const canCancelBooking = (booking) => {
  const stages = [
    BookingStageEnumKey.EnquiryRequest,
    BookingStageEnumKey.InstantBooking,
    BookingStageEnumKey.Invoice,
    BookingStageEnumKey.AwaitingOwnerApproval,
    BookingStageEnumKey.DepositTaken,
    BookingStageEnumKey.SecurityDepositTaken,
    BookingStageEnumKey.FullPaymentReceived,
    BookingStageEnumKey.AwaitingPayment
  ]
  return stages.includes(booking.Stage)
}

export const amountToPay = (booking) => {
  return booking.AmountDue
}

export const isBookingInUs = (booking) => booking.Country === COUNTRY_SHORTS.US
export const isBookingInCanada = (booking) => booking.Country === COUNTRY_SHORTS.CA
export const getRvType = (booking) => booking.RVSnapshot.RVType

export const bookingTotal = (booking) => {
  const renterTotal = getRenterTotalAmount(booking.Financial)
  if (!booking.Financial.Discount && !booking.Financial.CreditsToBeApplied) return renterTotal
  return Math.max(renterTotal - (booking.Financial.Discount + booking.Financial.CreditsToBeApplied), 0)
}

export const getRentalType = (booking) => booking.RVSnapshot.RentalType
export const isMotorHome = (booking) => getRentalType(booking) === RentalTypeEnumKey.MotorHome
export const isTravelTrailer = (booking) => getRentalType(booking) === RentalTypeEnumKey.TravelTrailer
export const isRVCottage = (booking) => getRentalType(booking) === RentalTypeEnumKey.RVCottage

export const bookingHasDelivery = (booking) => {
  if (!booking) return false
  return rvUsageIsDelivery(booking.RvUsage)
}

export const rvUsageIsDelivery = (rvUsage) => {
  return [RvUsageType.Delivery, RvUsageType.StationaryAfterDelivery].includes(rvUsage)
}

export const stationaryAfterDelivery = (booking) => {
  if (!booking) return false
  return booking.RvUsage === RvUsageType.StationaryAfterDelivery
}

export const isPickup = (booking) => {
  if (!booking) return false
  return booking.RvUsage === RvUsageType.Pickup
}

export const hasCloseoutCharges = (booking) => {
  if (booking.AdditionalBookingCharges) {
    const charges = booking.AdditionalBookingCharges
    return charges.GeneratorCharges + charges.MileageCharges + charges.DamageCharges + charges.OtherCharges > 0
  }
  return false
}

export const hasPaymentInfo = (booking) => !booking.NoPaymentMethod

// Booking Preferences
const hasRequiredPreference = (booking, preferenceType) => {
  return booking?.BookingPreferences?.find(
    (preference) => preference.PreferenceType === preferenceType && preference.RequiredType !== 'NotRequired'
  )
}

const answerIsTrue = (preference) => {
  return preference?.RenterAnswer?.toLowerCase() === 'true'
}

export const experienceDriverPreference = (booking) => {
  return (
    !isRVCottage(booking) && hasRequiredPreference(booking, BookingPreferencesTypeEnumKey.AllowOnlyExperiencedSimilarRV)
  )
}

export const hasExperiencedDriver = (booking) => {
  return answerIsTrue(experienceDriverPreference(booking))
}

export const festivalsEventsPreference = (booking) => {
  return (
    !isRVCottage(booking) &&
    hasRequiredPreference(booking, BookingPreferencesTypeEnumKey.AllowFestivalEventsParticipation)
  )
}

export const isGoingToFestivalOrEvent = (booking) => {
  return answerIsTrue(festivalsEventsPreference(booking))
}

export const festivalsEventsPreferenceDestination = (booking) => {
  const destination = booking?.BookingPreferences?.find(
    (preference) =>
      preference.PreferenceType === BookingPreferencesTypeEnumKey.AllowFestivalEventsParticipation &&
      preference.RequiredType !== 'NotRequired'
  )
  return destination?.RenterAnswerDetails || ''
}

export const CardFailureMappings = Object.freeze({
  // contactIssuer
  call_issuer: 'contactIssuer',
  do_not_honor: 'contactIssuer',
  do_not_try_again: 'contactIssuer',
  duplicate_transaction: 'contactIssuer',
  fraudulent: 'contactIssuer',
  generic_decline: 'contactIssuer',
  invalid_account: 'contactIssuer',
  lost_card: 'contactIssuer',
  merchant_blacklist: 'contactIssuer',
  new_account_information_available: 'contactIssuer',
  no_action_taken: 'contactIssuer',
  not_permitted: 'contactIssuer',
  offline_pin_required: 'contactIssuer',
  restricted_card: 'contactIssuer',
  revocation_of_all_authorizations: 'contactIssuer',
  revocation_of_authorization: 'contactIssuer',
  security_violation: 'contactIssuer',
  service_not_allowed: 'contactIssuer',
  stolen_card: 'contactIssuer',
  stop_payment_order: 'contactIssuer',
  transaction_not_allowed: 'contactIssuer',
  approve_with_id: 'contactIssuer',
  issuer_not_available: 'contactIssuer',
  processing_error: 'contactIssuer',
  reenter_transaction: 'contactIssuer',
  try_again_later: 'contactIssuer',

  // tryDifferentCard
  authentication_required: 'tryDifferentCard',
  card_not_supported: 'tryDifferentCard',
  currency_not_supported: 'tryDifferentCard',
  online_or_offline_pin_required: 'tryDifferentCard',
  pin_try_exceeded: 'tryDifferentCard',
  testmode_decline: 'tryDifferentCard',

  // limitExceeded
  card_velocity_exceeded: 'limitExceeded',
  invalid_amount: 'limitExceeded',
  withdrawal_count_limit_exceeded: 'limitExceeded',

  // insufficientFunds
  insufficient_funds: 'insufficientFunds',

  // cardExpired
  expired_card: 'cardExpired',

  // wrongCVC
  incorrect_cvc: 'wrongCVC',
  invalid_cvc: 'wrongCVC',

  // wrongNumber
  incorrect_number: 'wrongNumber',
  invalid_number: 'wrongNumber',

  // incorrect_zip
  incorrect_zip: 'incorrectZip',

  // wrongExpiry
  invalid_expiry_month: 'wrongExpiry',
  invalid_expiry_year: 'wrongExpiry',

  // calendar unavailable
  calendar_unavailable: 'calendarUnavailable'
})

export const isBookingInStage = (booking, stage) => {
  return booking.Stage === stage
}

export const isBookingInStages = (booking, stages) => {
  return stages.includes(booking.Stage)
}

export const IsBookingCancelledPayoutGuarantee = (booking) => {
  return booking.IsIncomeProtected === true && booking.CancellationType === CancellationTypeEnumKey.AdminCancellation
}

export const totalRentalPayments = (booking) => {
  return booking.Financial.TotalRentalPayments
}

// In case the renter chooses the refund with credits we will provide 10% additional amount
export const rvezyCreditsAmount = (booking) => {
  let total =
    getRenterTotalAmount(booking.Financial) + booking.Financial.TotalCloseouts + booking.Financial.TotalDamages
  total = Number(total + total * 0.1).toFixed(2)
  return Number(total)
}

export const didPaymentFail = (booking) => {
  return (
    booking.PaymentFailed && !booking.NoPaymentMethod && !isBookingInStage(booking, BookingStageEnumKey.AwaitingPayment)
  )
}

export const originalAmount = (booking) => {
  return (
    getRenterTotalAmount(booking?.Financial) + booking?.Financial?.TotalCloseouts + booking?.Financial?.TotalDamages ??
    0
  )
}

export const isPrepaidStage = (stage, noPaymentMethod) => {
  return stage === BookingStageEnumKey.AwaitingOwnerApproval && !noPaymentMethod
}

export const getOwnerDeclineActionsFromReasons = ({ reason, details, rv = {}, hasExternalCalendar = false }) => {
  // skip processing if param is undefined
  if (!reason) return []

  switch (reason) {
    case DeclineOrCancelReasonEnumKey.MyRvIsNotAvailableForRentOnTheRequestedDates: {
      if (details === DeclineOrCancelReasonDetailsEnumKey.PersonalUse) return [DeclinationActions.UPDATE_CALENDAR]

      if (details === DeclineOrCancelReasonDetailsEnumKey.BookedElsewhere) {
        const actions = [DeclinationActions.UPDATE_CALENDAR]
        if (hasExternalCalendar) return actions
        return [...actions, DeclinationActions.SYNC_CALENDAR]
      }

      if (details === DeclineOrCancelReasonDetailsEnumKey.AmNotAvailableToReleaseOrReturnRv)
        return [DeclinationActions.UPDATE_CALENDAR, DeclinationActions.CALENDAR_SETTINGS]

      // other
      const actions = [DeclinationActions.UPDATE_CALENDAR, DeclinationActions.CALENDAR_SETTINGS]

      if (hasExternalCalendar) return actions

      return [...actions, DeclinationActions.SYNC_CALENDAR]
    }
    case DeclineOrCancelReasonEnumKey.MyRvIsNotFitForTheRoad:
    case DeclineOrCancelReasonEnumKey.ExtenuatingCircumstances:
      return [DeclinationActions.UPDATE_CALENDAR]

    case DeclineOrCancelReasonEnumKey.HaveConcernsWithThisRenter:
      if (details === DeclineOrCancelReasonDetailsEnumKey.NotComfortableRenterExperience) {
        const actions = [DeclinationActions.LEARN_HOST_PROTECTION]

        if (rv.AllowOnlyExperiencedSimilarRV) return actions

        if (isRVTravelTrailer(rv)) return [...actions, DeclinationActions.TOWING_PREFERENCES]

        return [...actions, DeclinationActions.DRIVING_PREFERENCES]
      }

      if (details === DeclineOrCancelReasonDetailsEnumKey.RenterIsTakingMyRvToFestivalOrEvent) {
        const actions = [DeclinationActions.LEARN_HOST_PROTECTION]

        if (rv.AllowFestivalEventsParticipation === AllowFestivalEventsParticipation.No)
          return [...actions, DeclinationActions.EVENT_PREFERENCES]

        return actions
      }

      if (
        [
          DeclineOrCancelReasonDetailsEnumKey.MinimumRentalDaysNotMet,
          DeclineOrCancelReasonDetailsEnumKey.NoLongerRentingForTwoDayTrips
        ].includes(details)
      )
        return [DeclinationActions.CALENDAR_SETTINGS]

      return [DeclinationActions.LEARN_HOST_PROTECTION]

    case DeclineOrCancelReasonEnumKey.ThisRvHasBeenSoldOrWillBeSold:
      return [DeclinationActions.UPDATE_CALENDAR, DeclinationActions.UNPUBLISH]

    // Other
    default: {
      const otheractions = [DeclinationActions.UPDATE_CALENDAR, DeclinationActions.CALENDAR_SETTINGS]

      if (hasExternalCalendar) return otheractions

      return [...otheractions, DeclinationActions.SYNC_CALENDAR]
    }
  }
}

export const isPaid = (booking) => {
  if (!booking) return false
  return Boolean(booking.PaymentMethod && !booking.PaymentFailed)
}

export const numberOfNights = (booking) => {
  if (!booking) return 0
  return numberOfNightsBetweenDates(booking.DateStart, booking.DateEnd)
}

export const numberOfNightsBetweenDates = (dateStart, dateEnd) => {
  if (!dateStart || !dateEnd) return 0
  return dayjs(dateEnd).diff(dayjs(dateStart), 'days')
}

export const getBadgeColorForHost = (stage) => {
  const lookup = {
    [BookingStageEnumKey.AwaitingOwnerApproval]: BadgeColors.Yellow,
    [BookingStageEnumKey.PendingDispute]: BadgeColors.Yellow,
    [BookingPseudoStage.PendingChangeRequest]: BadgeColors.Yellow,
    [BookingPseudoStage.PrepaidAOA]: BadgeColors.Yellow,

    [BookingStageEnumKey.AwaitingPayment]: BadgeColors.Blue,
    [BookingStageEnumKey.Invoice]: BadgeColors.Blue,
    [BookingStageEnumKey.EnquiryRequest]: BadgeColors.Blue,

    [BookingStageEnumKey.InstantBooking]: BadgeColors.Green,
    [BookingStageEnumKey.DepositTaken]: BadgeColors.Green,
    [BookingStageEnumKey.FullPaymentReceived]: BadgeColors.Green,
    [BookingStageEnumKey.SecurityDepositTaken]: BadgeColors.Green,
    [BookingStageEnumKey.TripStarted]: BadgeColors.Green,
    [BookingStageEnumKey.Completed]: BadgeColors.Green,
    [BookingStageEnumKey.PendingPayout]: BadgeColors.Green,
    [BookingStageEnumKey.CompleteInProcess]: BadgeColors.Green,
    [BookingStageEnumKey.PendingPayoutOnCancel]: BadgeColors.Green,
    [BookingStageEnumKey.ProcessCompleteFail]: BadgeColors.Green,

    [BookingStageEnumKey.CanceledByTheOwner]: BadgeColors.Red,
    [BookingStageEnumKey.DeclinedByTheOwner]: BadgeColors.Red,
    [BookingStageEnumKey.ExpiredRequest]: BadgeColors.Red,

    [BookingStageEnumKey.CanceledByTheRenter]: BadgeColors.Grey,
    [BookingStageEnumKey.CanceledByTheAdmin]: BadgeColors.Grey,
    [BookingStageEnumKey.DeclinedByTheRenter]: BadgeColors.Grey,
    [BookingStageEnumKey.ExpiredNoPayment]: BadgeColors.Grey
  }

  return lookup[stage] || BadgeColors.Grey
}

export const getBadgeColorForGuest = (stage) => {
  const lookup = {
    [BookingStageEnumKey.PendingDispute]: BadgeColors.Yellow,
    [BookingStageEnumKey.AwaitingPayment]: BadgeColors.Yellow,

    [BookingStageEnumKey.AwaitingOwnerApproval]: BadgeColors.Blue,
    [BookingStageEnumKey.Invoice]: BadgeColors.Blue,
    [BookingStageEnumKey.EnquiryRequest]: BadgeColors.Blue,
    [BookingPseudoStage.PendingChangeRequest]: BadgeColors.Blue,

    [BookingStageEnumKey.InstantBooking]: BadgeColors.Green,
    [BookingStageEnumKey.DepositTaken]: BadgeColors.Green,
    [BookingStageEnumKey.FullPaymentReceived]: BadgeColors.Green,
    [BookingStageEnumKey.SecurityDepositTaken]: BadgeColors.Green,
    [BookingStageEnumKey.TripStarted]: BadgeColors.Green,
    [BookingStageEnumKey.Completed]: BadgeColors.Green,
    [BookingStageEnumKey.PendingPayout]: BadgeColors.Green,
    [BookingStageEnumKey.CompleteInProcess]: BadgeColors.Green,
    [BookingStageEnumKey.PendingPayoutOnCancel]: BadgeColors.Green,
    [BookingStageEnumKey.ProcessCompleteFail]: BadgeColors.Green,

    [BookingStageEnumKey.CanceledByTheOwner]: BadgeColors.Grey,
    [BookingStageEnumKey.DeclinedByTheOwner]: BadgeColors.Grey,
    [BookingStageEnumKey.ExpiredRequest]: BadgeColors.Grey,
    [BookingStageEnumKey.CanceledByTheRenter]: BadgeColors.Grey,
    [BookingStageEnumKey.CanceledByTheAdmin]: BadgeColors.Grey,
    [BookingStageEnumKey.DeclinedByTheRenter]: BadgeColors.Grey,
    [BookingStageEnumKey.ExpiredNoPayment]: BadgeColors.Grey
  }

  return lookup[stage] || BadgeColors.Grey
}

export const getCancellationPolicyByStartDate = (startDate) => {
  const diffDays = dayjs(startDate).diff(dayjs(), 'days')

  if (!startDate) return CancellationPolicyOptions.NoDatesSelected

  if (diffDays <= 7) {
    return CancellationPolicyOptions.LessThan7Days
  } else if (diffDays > 7 && diffDays <= 30) {
    return CancellationPolicyOptions.Between8To30Days
  } else if (diffDays > 30 && diffDays <= 60) {
    return CancellationPolicyOptions.MoreThan30Days
  } else {
    return CancellationPolicyOptions.MoreThan60Days
  }
}

export const getProtectionPlan = (booking) => {
  if (!booking) return

  return booking.ProtectionLevel?.toLowerCase()
}

/**
 * Gets the roadside assistance state from a booking.
 */
export const getRoadsideAssistance = (booking) => {
  if (!booking) return

  return booking.RoadsideAssistanceState
}

/**
 * Filters addons to only include RVAddons or a CustomTotal addon.
 */
export const getAddons = (addon) => {
  return (
    addon.AddOnType === 0 || addon.AddOnType === 'RVAddOn' || addon.AddOnType === 3 || addon.AddOnType === 'CustomTotal'
  )
}

/**
 * Filters addons to only include RVAddons.
 */
export const getRVAddons = (addon) => {
  return addon.AddOnType === 0 || addon.AddOnType === 'RVAddOn'
}

export const getSmartPricingPercentage = (booking) => booking.RVSnapshot.SmartPricingPercentage
export const getSmartPricingMinPrice = (booking) => booking.RVSnapshot.SmartPricingMinPrice

export const getHasSmartPricing = (booking) => booking.RVSnapshot.HasSmartPricingApplied

export const getSmartPricingRate = (booking) => (getHasSmartPricing(booking) ? getDefaultPrice(booking) : undefined)

export const getOriginalRate = (booking) => {
  if (!booking || !booking.RVSnapshot || !booking.RVSnapshot.OriginalDefaultPrice) return

  const originalRate = Math.round(booking.RVSnapshot.OriginalDefaultPrice)
  return originalRate
}

export const getDefaultPrice = (booking) => booking?.RVSnapshot?.DefaultPrice

export const getRate = (booking) => {
  return getOriginalRate(booking) || getDefaultPrice(booking)
}
export const isFomoTagEnabledBooking = (booking) => !isBookingEzyMatch(booking) && !isBookingCustomQuote(booking)
export function getDistancePriceKms(booking) {
  return booking.RVSnapshot.IsChargeMileage ? booking.RVSnapshot.SurchargePerExtraKM ?? undefined : undefined
}

export const canEditDates = (booking) => {
  return [
    BookingStageEnumKey.AwaitingOwnerApproval,
    BookingStageEnumKey.AwaitingPayment,
    BookingStageEnumKey.DepositTaken,
    BookingStageEnumKey.SecurityDepositTaken,
    BookingStageEnumKey.FullPaymentReceived,
    BookingStageEnumKey.TripStarted
  ].includes(booking.Stage)
}

export const canEditDetails = (booking) => {
  return [
    BookingStageEnumKey.AwaitingOwnerApproval,
    BookingStageEnumKey.AwaitingPayment,
    BookingStageEnumKey.InstantBooking,
    BookingStageEnumKey.DepositTaken,
    BookingStageEnumKey.SecurityDepositTaken,
    BookingStageEnumKey.FullPaymentReceived
  ].includes(booking.Stage)
}

export const canSendChangeRequestNeedsApprovalOtherUser = (booking) => {
  return (
    isBookingInStages(booking, [
      BookingStageEnumKey.InstantBooking,
      BookingStageEnumKey.DepositTaken,
      BookingStageEnumKey.SecurityDepositTaken,
      BookingStageEnumKey.FullPaymentReceived,
      BookingStageEnumKey.TripStarted
    ]) && !booking.PaymentFailed
  )
}

export const getNewRentalAgreementForRenters = ({ isInsured, isCanada, deductible, plan }) => {
  function getConditionalTitle(name) {
    return `rentalAgreement.steps.conditionals.conditionals.new.renter.${name}.title`
  }
  function getConditionalDescription(name) {
    return `rentalAgreement.steps.conditionals.conditionals.new.renter.${name}.description`
  }
  const items = [
    {
      type: 'title',
      content: {
        path: 'rentalAgreement.steps.conditionals.titles.new.main'
      }
    },
    isInsured
      ? isCanada
        ? {
            type: 'conditional',
            title: {
              path: getConditionalTitle('protectionCanada')
            },
            description: {
              path: getConditionalDescription('protectionCanada'),
              args: { amount: deductible, plan }
            },
            selected: false
          }
        : {
            type: 'conditional',
            title: {
              path: getConditionalTitle('protectionUSA')
            },
            description: {
              path: getConditionalDescription('protectionUSA'),
              args: { amount: deductible, plan }
            },
            selected: false
          }
      : {
          type: 'conditional',
          description: {
            path: 'rentalAgreement.steps.conditionals.conditionals.renter.insurance.description'
          },
          selected: false
        },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('driver')
      },
      description: {
        path: getConditionalDescription('driver')
      },
      selected: false
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('inspection')
      },
      description: {
        path: getConditionalDescription('inspection')
      },
      selected: false
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('gasPropaneWasteCleaning')
      },
      description: {
        path: getConditionalDescription('gasPropaneWasteCleaning')
      },
      selected: false
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('rvCondition')
      },
      description: {
        path: getConditionalDescription('rvCondition')
      },
      selected: false
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('tireWindshield')
      },
      description: {
        path: getConditionalDescription('tireWindshield')
      },
      selected: false
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('roadside')
      },
      description: {
        path: getConditionalDescription('roadside')
      },
      selected: false
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('accidentDamage')
      },
      description: {
        path: getConditionalDescription('accidentDamage')
      },
      selected: false
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('guestLiability')
      },
      description: {
        path: getConditionalDescription('guestLiability')
      },
      selected: false
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('additionalCharges')
      },
      description: {
        path: getConditionalDescription('additionalCharges')
      },
      selected: false
    }
  ]

  return items
}

export const getNewRentalAgreementForOwners = ({ isMotorhome, isTravelTrailer }) => {
  function getConditionalTitle(name) {
    return `rentalAgreement.steps.conditionals.conditionals.new.owner.${name}.title`
  }
  function getConditionalDescription(name) {
    return `rentalAgreement.steps.conditionals.conditionals.new.owner.${name}.description`
  }
  const items = [
    {
      type: 'title',
      content: {
        path: 'rentalAgreement.steps.conditionals.titles.new.main'
      }
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('rvRelease')
      },
      description: {
        path: getConditionalDescription('rvRelease')
      },
      selected: false
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('inspection')
      },
      description: {
        path: getConditionalDescription('inspection')
      },
      selected: false
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('cleaning')
      },
      description: {
        path: getConditionalDescription('cleaning')
      },
      selected: false
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('tires')
      },
      description: {
        path: getConditionalDescription('tires')
      },
      selected: false
    },
    isMotorhome && {
      type: 'conditional',
      title: {
        path: getConditionalTitle('fluids')
      },
      description: {
        path: getConditionalDescription('fluids')
      },
      selected: false
    },
    isTravelTrailer && {
      type: 'conditional',
      title: {
        path: getConditionalTitle('bearings')
      },
      description: {
        path: getConditionalDescription('bearings')
      },
      selected: false
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('brakes')
      },
      description: {
        path: getConditionalDescription('brakes')
      },
      selected: false
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('lights')
      },
      description: {
        path: getConditionalDescription('lights')
      },
      selected: false
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('safetyEquipment')
      },
      description: {
        path: getConditionalDescription('safetyEquipment')
      },
      selected: false
    },
    {
      type: 'conditional',
      title: {
        path: getConditionalTitle('drivers')
      },
      description: {
        path: getConditionalDescription('drivers')
      },
      selected: false
    },
    {
      type: 'title',
      content: {
        path: 'rentalAgreement.steps.conditionals.titles.owner.secondary'
      },
      selected: false
    },
    {
      type: 'conditional',
      description: {
        path: getConditionalDescription('legalAuthority')
      },
      selected: false
    },
    {
      type: 'conditional',
      description: {
        path: getConditionalDescription('rvRegistered')
      },
      selected: false
    },
    {
      type: 'conditional',
      description: {
        path: getConditionalDescription('contactInformation')
      },
      selected: false
    },
    {
      type: 'conditional',
      description: {
        path: getConditionalDescription('rvSafe')
      },
      selected: false
    }
  ]

  return items.filter(Boolean)
}
