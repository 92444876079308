
export default {
  name: 'BannerRelease',

  computed: {
    hasReleaseBanner() {
      return this.$experiment.isEnabled('rvz21713January2025ReleaseBanner')
    }
  }
}
